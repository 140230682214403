// client/src/components/Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png'; 

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        <img src={logo} alt="Origin MC Logo" className="navbar-logo" />
      </Link>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/patchnotes">Updates</Link></li>
        <li><Link to="/contests">Contests</Link></li>
        {/* <li><Link to="/install-instructions">Install Instructions</Link></li> */}
        <li><Link to="/rules">Rules</Link></li>
        <li><Link to="/faq">FAQ</Link></li>
        {/* <li><Link to="/history-of-winners">History of Winners</Link></li> */}
        {/* <li><Link to="/modlist">Mod List</Link></li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
