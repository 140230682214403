import React, { useState, useEffect } from 'react';
import './TurtleScripts.css';

const TurtleScripts = () => {
  const [scripts, setScripts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedScript, setSelectedScript] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [fontSize, setFontSize] = useState(16); // Default font size in pixels

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/TurtleScripts/scripts.json`)
      .then(response => response.json())
      .then(data => setScripts(data.scripts))
      .catch(error => console.error('Error fetching scripts:', error));
  }, []);

  const handleScriptClick = (script) => {
    const filePath = `${process.env.PUBLIC_URL}/TurtleScripts/${script.name}`;
    fetch(filePath)
      .then(response => response.text())
      .then(content => {
        setSelectedScript(script);
        setFileContent(content);
      })
      .catch(error => console.error('Error fetching file content:', error));
  };

  const filteredScripts = scripts.filter(script =>
    script.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="TurtleScripts">
      <h2 style={{ color: 'white' }}>Turtle Scripts</h2>
        <p style={{ color: 'red' }}>I will NOT help you setup or explain ANY code.</p>
        <br></br>
      <input
        type="text"
        placeholder="Search scripts..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="search-input"
      />
      {selectedScript ? (
        <div className="script-detail">
          <h3>{selectedScript.name}</h3>
          <div className="font-size-controls">
            <button onClick={() => setFontSize(prevSize => prevSize + 2)}>A+</button>
            <button onClick={() => setFontSize(prevSize => Math.max(prevSize - 2, 10))}>A-</button>
          </div>
          <pre className="script-content" style={{ fontSize: `${fontSize}px` }}>{fileContent}</pre>
          <div className="script-actions">
            <a href={`${process.env.PUBLIC_URL}/TurtleScripts/${selectedScript.name}`} download className="download-button">
              Download {selectedScript.name}
            </a>
            <button onClick={() => setSelectedScript(null)} className="back-button">
              Back to List
            </button>
          </div>
        </div>
      ) : (
        <ul>
          {filteredScripts.map((script, index) => (
            <li key={index} className="script-item" onClick={() => handleScriptClick(script)}>
              <div className="script-info">
                <span className="script-link">{script.name}</span>
                <span className="script-author">by {script.author}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TurtleScripts;
