// client/src/components/InstallInstructions.js

import React from 'react';
import './InstallInstructions.css';

const InstallInstructions = () => {
  return (
    <div className="InstallInstructions">
      <h2>Install Instructions</h2>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/1sdPzSJbeiU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <p>Written Tutorial:</p>
      <ol>
        <li>Download the ZIP file from<a className="download-link" href="https://drive.google.com/file/d/1QoAmE0muVVtX6Gv7cChxIQpDd8NmzARr/view?usp=sharing
" target="_blank" rel="noopener noreferrer"> here</a>.</li>
        <li>Open up CurseForge and go to "Create Custom Profile".</li>
        <li>Select "Import" a previously created profile.</li>
        <li>Choose the ZIP file you just downloaded.</li>
        <li>You're done!</li>
      </ol>
    </div>
  );
};

export default InstallInstructions;
