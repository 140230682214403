// client/src/components/Contests.js

import React, { useState, useEffect } from 'react';
import './Contests.css';

const contestsData = [
  {
    category: "Overall Theme: Evil",
    description: "The theme 'Evil' encourages participants to embrace the darker, sinister aspects of creativity. This can include anything from ominous structures and menacing aesthetics to functional traps and evil lairs. The key is to convey a sense of malevolence, foreboding, and intimidation through the design and functionality of the builds.",
    endDate: "2024-9-28T23:00:00Z"
  },
  {
    category: "Overall building challenge",
    prize: "$50 USD sponsored by ME UNBUTTEREDBAGEL",
    theme: "Evil",
    rules: [
      "Teaming Is Allowed, the prize will be split evenly between members no exceptions",
      "It will be in survival",
      "Builds must adhere to the 'Evil' theme, showcasing elements like dark colors, eerie lighting, and menacing features.",
      "No sabotaging other players.",
      "Submissions will be collected <a href='https://forms.gle/WmLdHQNLCjRoprz39' target='_blank' rel='noopener noreferrer'>HERE</a>",
      "Not complying with any of these will be grounds for disqualification."
    ],
    judging: [
      "Looks: The visual appeal and how effectively the build captures the essence of evil.",
      "Functionality: How well the build performs any intended functions, such as traps or evil mechanisms.",
      "Theme Adherence: How closely the build follows the 'Evil' theme, including atmosphere, creativity, and storytelling elements."
    ],
    endDate: "2024-9-28T23:00:00Z"
  },
  {
    category: "WORST Build",
    prize: "$100 USD sponsored by Glimpse",
    theme: "Create the WORST build you can",
    rules: [
      "Teaming Is Allowed, the prize will be split evenly between members no exceptions",
      "It will be in survival",
      "No sabotaging other players.",
      "There needs to be some form of effort to count",
      "It must be livable",
      "Must follow the main theme",
      "Submissions will be collected <a href='https://forms.gle/WmLdHQNLCjRoprz39' target='_blank' rel='noopener noreferrer'>HERE</a>",
      "Not complying with any of these will be grounds for disqualification."
    ],
    judging: ["Judging will be done by Glimpse"],
    endDate: "2024-9-28T23:00:00Z"
  },
  {
    category: "Looks",
    prize: "€25 Euros sponsored by Darx and a drawing sponsored by Musical",
    theme: "Evil",
    rules: [
      "Teaming Is Allowed, the prize will be split evenly between members no exceptions",
      "It will be in survival",
      "No sabotaging other players.",
      "Submissions will be collected <a href='https://forms.gle/WmLdHQNLCjRoprz39' target='_blank' rel='noopener noreferrer'>HERE</a>",
      "Not complying with any of these will be grounds for disqualification."
    ],
    judging: [
      "Aesthetics: How visually appealing and terrifying the build is.",
      "Theme Adherence: How well the build captures and represents the 'Evil' theme through its design.",
      "Creativity: Unique and imaginative use of materials and structure to convey evilness.",
      "Originality: The build must be the original work of the participant or team, showcasing their own creative ideas and execution. It should not be a replica or heavily inspired by existing builds, ensuring that each submission brings a fresh and unique interpretation of the 'Evil' theme."
    ],
    endDate: "2024-9-28T23:00:00Z"
  },
  {
    category: "Functionality",
    prize: "$50 CAD sponsored by Deus",
    theme: "Evil",
    rules: [
      "Teaming Is Allowed, the prize will be split evenly between members no exceptions",
      "It will be in survival",
      "No sabotaging other players.",
      "Submissions will be collected <a href='https://forms.gle/WmLdHQNLCjRoprz39' target='_blank' rel='noopener noreferrer'>HERE</a>",
      "Not complying with any of these will be grounds for disqualification."
    ],
    judging: [
      "Functionality: How well the build performs its intended function. This includes efficiency, reliability, and effectiveness.",
      "Innovation: The uniqueness and originality of the functionality. Creative and novel solutions will be rewarded.",
      "Complexity: The technical difficulty involved in creating the functionality. More complex builds that demonstrate advanced skills will score higher.",
      "Usability: How easy it is for others to understand and use the functionality. Clear instructions and user-friendly designs are important.",
      "Theme Adherence: The extent to which the functionality enhances the evil atmosphere and concept."
    ],
    endDate: "2024-9-28T23:00:00Z"
  },
];
const CountdownTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="countdown-timer">
      {timerComponents.length ? timerComponents : <span>Contest Is Over!</span>}
    </div>
  );
};

const Contests = () => {
  return (
    <div className="Contests">
      <h2>Contests</h2>
      <h3>All teams created must be ran by UnbutteredBagel and have both parties agree</h3>
      {contestsData.map((contest, index) => (
        <div className="contest" key={index}>
          <h3>{contest.category}</h3>
          {contest.description && <p>{contest.description}</p>}
          {contest.prize && <p><strong>Prize:</strong> {contest.prize}</p>}
          {contest.theme && <p><strong>Theme:</strong> {contest.theme}</p>}
          <h4>Time Left: <CountdownTimer endDate={contest.endDate} /></h4>
          {contest.rules && (
            <>
              <h4>Rules:</h4>
              <ul>
                {contest.rules.map((rule, ruleIndex) => (
                  <li key={ruleIndex} dangerouslySetInnerHTML={{ __html: rule }} />
                ))}
              </ul>
            </>
          )}
          {contest.judging && (
            <>
              <h4>Judging:</h4>
              <ul>
                {contest.judging.map((criteria, criteriaIndex) => (
                  <li key={criteriaIndex}>{criteria}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Contests;