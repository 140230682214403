import React, { useState, useEffect } from 'react';
import './PatchNotes.css';

const patchNotesData = [
  
{
  version: "1.1.02",
  date: "2024-07-12",
  notes: [
    {
      text: "Removed: being able to lock your chest",
      tags: ['Balance'],
      mod: "Chestlock",
      comment: "There was some conflicts."
    },
    {
      text: "Removed: Md chat support ",
      tags: ['Balance'],
      mod: "Chat Colours",
      comment: "There was some conflicts."
    },
  ]
},
{
  version: "1.1.01",
  date: "2024-07-12",
  notes: [
    {
      text: "Harvest With Ease: Requies None->Requires Hoe",
      tags: ['Balance'],
      mod: "Harvest With Ease",
      comment: "This makes it so that there is some cost to use the mod"
    },

    {
      text: "Harvest With ease increment None-> Small",
      tags: ['Balance'],
      mod: "Harvest With Ease",
      comment: "This allows for more value in using hgiher tier items"
    },

    {
      text: "Flesh to leather: Now requires flesh in a 2x2 grid",
      tags: ['Balance'],
      mod: "Just Another Rotten Flesh To Lether Mod",
      comment: "This just gives it so its a bit harder to get lether but is still worth it"
    },

    {
      text: "Added waystones",
      tags: ['Feature'],
      mod: "Waystones",
      comment: "This allows us to have a way to get back to spawn easier"
    },

    {
      text: "Waystones exp cost 30x when doing dimensional travel. Only Owners can change names, natural spawns are unbreakable, 600 sec cd",
      tags: ['Balance'],
      mod: "Waystones",
      comment: "General Balance"
    },

    {
      text: "/wp dropped from 3->2",
      tags: ['Balance'],
      mod: "shw",
      comment: "With waypoints I hope to phase this out"
    },

    {
      text: "Added configured",
      tags: ['Feature'],
      mod: "configured",
      comment: "Added configured mod so that its easier to change configs"
    }
  ]
},
  {
    version: "1.1",
    date: "2024-07-12",
    notes: [
      { text: "Added a way to sort your inventory + auto stack refill", tags: ['Feature'], mod: "Inventory Tweaks", comment: "Added inventory tweaks for autosorting + stack refilling" },
      { text: "Added natures compass", tags: ['Feature'], mod: "Nature's Compass", comment: "Added natures compass to find biomes easier" },
      { text: "Added Better compatability cheker", tags: ['Feature'], mod: "Better Compatibility Checker", comment: "Added this so forge stop yelling at us" },
      { text: "Added a trading block", tags: ['Feature'], mod: "Tom's Trading Network", comment: "Added a way for players to setup shops along with me" },
      { text: "Added mob trophy drops", tags: ['Feature'], mod: "trofers", comment: "Added mob trophies for slightly mor variety" },
      { text: "Added Improved sign editing", tags: ['Feature'], mod: "Improved Sign Editing", comment: "Added a better way to edit signs. QOL" },
      { text: "Added Xray Snitcher", tags: ['Feature'], mod: "Xray Snith", comment: "Added an xray snitching mod. This will only be used to call out people with it installed and will be human verified before kciks" },
      { text: "Added a ModList Snooper(Sends what mods you have installed to me)", tags: ['Feature'], mod: "Modlist Snooper", comment: "This mod will help keep people honest about what mods they are using to attmempt to prevent people from using unfair mods" },
      { text: "Added new death messages", tags: ['Feature'], mod: "New Death Messages", comment: "Added more death messages to make the deaths more funny" },
      { text: "Added Chest lock mod", tags: ['Feature'], mod: "Chestlock", comment: "Added a mod so that people can lock their chests. Will watch carefully as we have other storage mods coming in. So there may be more conflicts" },
      { text: "Added a way for players to go AFK and not affect sleep", tags: ['Feature'], mod: "Afk Command", comment: "Added a way so that when people afk it does not affect the people who are online from sleeping" },
      { text: "Added MD chat support", tags: ['Feature'], mod: "Chat Colours", comment: "Alows people to use MD syntax in text.  Will have to watch carefully as we are adding more chatting mods" },
      { text: "Added heads next to chat", tags: ['Feature'], mod: "Chat Heads", comment: "Displays a head of the person talking in the chat so it's easier to see who it is" },
      { text: "Added comic bubbles chat", tags: ['Feature'], mod: "Comic Bubbles Chat", comment: "Will watch reactions carefully, but essentially makes local talking more interative by having a speech bubble about you" },
      { text: "Added a new storage mod", tags: ['Feature'], mod: "Tom's Storage", comment: "After many talks about a storage mod. Tom's storage seems to be the most lightweight and fair" },
      { text: "Added falling trees mod", tags: ['Feature'], mod: "Falling Tree", comment: "Added a mod so that trees are easier to fell" },
      { text: "Added icarus mod", tags: ['Feature'], mod: "Icarus", comment: "Added a mod to have skinned elyctras with the icarus mod" },
      { text: "QoL farming additions", tags: ['Feature'], mod: "Farming With Ease", comment: "This should help QoL of the game and make farming a bit easier" },
      { text: "Added a mob capturing tool", tags: ['Feature'], mod: "Mob Capturing Tool", comment: "Added a way for players to capture mobs and move them around easier" },
      { text: "Added more blocks for players to work with", tags: ['Feature'], mod: "Framed Blocks", comment: "This mod allows players to get more creative with how they build adding many different blocks for building" },
      { text: "Added a way to see the food value of items", tags: ['Feature'], mod: "appleskin", comment: "Added a way for people to see how much hunger restores" },
      { text: "Added Computers, ( I will not provide any help for this, @xeroxiin for pc help )", tags: ['Feature'], mod: "cc tweaked", comment: "Will have to watch this one very carefully. Any misuse will result in repercussions" },
      { text: "Added a way to link items in chat", tags: ['Feature'], mod: "Show me what you've got", comment: "Added a way for players to link items in chat to show off items" },
      { text: "Mobs now drop mob heads", tags: ['Feature'], mod: "just-mob-heads", comment: "Mobs now drop heads, this with the trophies mod will make killing mobs more fun and more rewarding" },
      { text: "BEEEEEEEEES", tags: ['Feature'], mod: "Productive Bees", comment: "Added more productive bees, they should provide more farming oppurtunities for players to work with" },
      { text: "Added a way for you to always see GUI bars", tags: ['Feature'], mod: "Leave My Bars Alone", comment: "This makes it so that when you're on a horse it doesn't remove you rGUI bars" },
      { text: "Rotten flesh can now be converted to leather", tags: ['Feature'], mod: "Just Another Rotten Flesh To Lether Mod", comment: "This makes rotten flesh actually useful and makes it easier to get leather rather then having a giant cow farm" }
    ]
  },
  {
    version: "1.0.1",
    date: "2024-07-08",
    notes: [
      { text: "Big Zombie chances decreased: 5% -> 1%", tags: ["Balance"], mod: "BigZombie", comment: "WHY were there so many big zombies at a 5%? I have no idea. We at 1% now." },
      { text: "Backpacks: Can carry multiple", tags: ["Feature"], mod: "Backpacked", comment: "You can now hold multiple backpacks to reduce load of items." },
      { text: "Pick Pocketing: No longer can pick pocket", tags: ["Balance"], mod: "Backpacked", comment: "We were unsure if this was for players or not. So we removed it." },
      { text: "WP: Waypoint number increased from 1 -> 3", tags: ["Feature"], mod: "shw", comment: "I've been convinced to increase this to 3." },
      { text: "Server RAM: Increased from 8 -> 12 GB", tags: ["Performance"], mod: "", comment: "Increased server RAM to improve performance and handle chunks being loaded." },
      { text: "Cosmetic Armor Rework: On death, keep your Cosmetics on", tags: ["Feature", "Cosmetic"], mod: "Cosmetic Armor Rework", comment: "Players will now retain their cosmetic items on death." },
      { text: "Added a TP hub at spawn", tags: ["In-Game"], mod: "", comment: "Added a teleportation hub at the spawn point so that players can get to people who want to be found easier" },
    ]
  },
  {
    version: "1.0.0",
    date: "2024-07-06",
    notes: [
      { text: "Initial release of Origin MC", tags: ["Release"], mod: "", comment: "This is the initial release of the game." },
      { text: "Server Ram: 8", tags: ["Performance"], mod: "", comment: "Initial server RAM allocation." },
      { text: "Keep Inventory: ON", tags: ["Balance"], mod: "", comment: "Inventory will be kept on death to reduce player frustration." },
      { text: "WP: Set at 1 home and 1 waypoint.", tags: ["Balance"], mod: "shw", comment: "Set initial waypoints to aid in navigation." },
    ]
  }
];

const getUniqueMods = (data) => {
  const modsSet = new Set();
  data.forEach(release => {
    release.notes.slice().sort((a, b) => a.mod.localeCompare(b.mod)).forEach(note => {
      if (note.mod !== ""){
        modsSet.add(note.mod);
      }
    });
  });
  return ["All", ...Array.from(modsSet)];
};

const Modal = ({ show, onClose, content }) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <span className="modal-close" onClick={onClose}>&times;</span>
        <div className="modal-content">
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

const PatchNotes = () => {
  const [selectedTag, setSelectedTag] = useState("All");
  const [selectedMod, setSelectedMod] = useState("All");
  const [modalContent, setModalContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mods, setMods] = useState([]);

  useEffect(() => {
    setMods(getUniqueMods(patchNotesData));
  }, []);

  const tags = ["All", "Balance", "Feature", "Bugfix", "Performance", "Cosmetic", "Release", "In-Game"];

  const filteredNotes = patchNotesData.map(release => ({
    ...release,
    notes: release.notes.filter(note =>
      (selectedTag === "All" || note.tags.includes(selectedTag)) &&
      (selectedMod === "All" || note.mod === selectedMod)
    )
  })).filter(release => release.notes.length > 0);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  return (
    <div className="PatchNotes">
      <h2>Patch Notes</h2>
      <div className="filters">
        <div className="tags">
          {tags.map(tag => (
            <button
              key={tag}
              className={`tag ${selectedTag === tag ? 'selected' : ''}`}
              onClick={() => setSelectedTag(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
        <div className="mods">
          <select onChange={e => setSelectedMod(e.target.value)} value={selectedMod}>
            {mods.map(mod => (
              <option key={mod} value={mod}>{mod}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="release-notes">
        {filteredNotes.map(release => (
          <div className="release" key={release.version}>
            <h3>{`Version ${release.version} - ${release.date}`}</h3>
            <ul>
              {release.notes.map((note, index) => (
                <li key={index}>
                  <strong>{note.text}</strong>
                  {note.comment && (
                    <span className="info-icon" onClick={() => openModal(note.comment)}>ℹ️</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <Modal show={isModalOpen} onClose={closeModal} content={modalContent} />
    </div>
  );
};

export default PatchNotes;
