// client/src/components/Rules.js

import React from 'react';
import './Rules.css';

const rulesData = [
  {
    title: "No Griefing of any kind",
    description: "This includes but is not limited to:",
    details: [
      "Mob Griefing",
      "Destruction",
      "Creation",
      "Chat Spamming",
      "Flooding",
      "TREE CREATION",
      "Fire"
    ]
  },
  { title: "No Stealing from any player." },
  { title: "No Squatting." },
  { title: "No PVP unless agreed on by both parties." },
  { title: "No X-raying." },
  { title: "Use Common Sense." },
  {
    title: "The final say comes down to @unbutteredbagel and any Mods that are added along the way (if needed)."
  }
];

const Rules = () => {
  return (
    <div className="Rules">
      <h2>Rules</h2>
      <p className="disclaimer">
        *These are not a complete list and can be added to or removed at any
        point*
      </p>
      <div className="rules-container">
        {rulesData.map((rule, index) => (
          <div className="rule-card" key={index}>
            <div className="rule-number">{index + 1}</div>
            <div className="rule-content">
              <h3>{rule.title}</h3>
              {rule.description && <p>{rule.description}</p>}
              {rule.details && (
                <ul>
                  {rule.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{detail}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="punishments">
        <h3>Breaking these rules will result in varying levels of punishment including but not limited to:</h3>
        <ul>
          <li>Server timeout</li>
          <li>Bans</li>
          <li>In-Game fines</li>
        </ul>
      </div>
      <div className="issues">
        <h3>In-Game Issues</h3>
        <p>
          All in-game issues should be settled amongst each other, and if there
          is no agreement, it should be brought up to @unbutteredbagel.
        </p>
      </div>
    </div>
  );
};

export default Rules;
