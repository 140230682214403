// client/src/components/ModList.js

import React, { useEffect, useState } from 'react';
import './ModList.css';

const ModList = () => {
  const [modList, setModList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://api.mcsrvstat.us/3/104.128.51.221:25577')
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.mods && data.mods) {
          setModList(data.mods);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching mod list:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="ModList">
      <h2>Server Mod List</h2>
      {loading ? (
        <p>Loading mods...</p>
      ) : (
        <ul>
          {modList.length > 0 ? (
            modList.map((mod, index) => <li key={index}>{mod}</li>)
          ) : (
            <p>No mods found. API does't find it correctly. Please check back later.</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default ModList;
