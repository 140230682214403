// client/src/components/FAQ.js

import React, { useState } from 'react';
import './FAQ.css';

const faqs = [
  {
    question: "Is there Keepinventory?",
    answer: "Yes, Keep inventory is on since this is a building focused run."
  },
  {
    question: "Are glitches allowed?",
    answer: "No, glitches are not allowed unless otherwise specified. Anyone caught using non-verified glitches will have appropriate repercussions."
  },
  {
    question: "What happens when you die?",
    answer: "You keep your items, *this is not a guarantee and I will not replace items that are lost in any bugs or glitches*. You also lose your *extra* hearts from Spice of Life Carrot."
  },
  {
    question: "How can I play MIDI files or my own MIDI device?",
    answer: "I'll make a video tutorial if anyone wants to know how to use that mod."
  },
  {
    question: "How do I test my ping?",
    answer: "Go to cmd (on Windows) and type `ping chi.playwn.co`."
  },
  {
    question: "MY MOUSE SPEED IS SO SLOW",
    answer: "Go to Options -> Controls -> Mouse Settings."
  },
  {
    question: "How do I set my home?",
    answer: "We have <a href='https://www.curseforge.com/minecraft/mc-mods/set-home-waypoints' target='_blank' rel='noopener noreferrer'>Shw Mod</a>, which allows you to use `/sethome` and `/wp [set, use, update, remove]`."
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="FAQ">
      <h2>FAQ</h2>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">{faq.question}</div>
          <div
            className="faq-answer"
            dangerouslySetInnerHTML={{ __html: faq.answer }}
          />
        </div>
      ))}
    </div>
  );
};

export default FAQ;
