import React, { useState, useEffect } from 'react';
import './ConfigFiles.css';
import './MidiPlayer.css';

const configFolders = [
  { name: 'Config', path: 'config' },
  { name: 'Server Config', path: 'serverconfig' }
];

const buildFileTree = (fileList) => {
  const fileTree = {};
  fileList.forEach(file => {
    const parts = file.split('/');
    let current = fileTree;
    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        current[part] = null;
      } else {
        if (!current[part]) current[part] = {};
        current = current[part];
      }
    });
  });
  return fileTree;
};

const filterFiles = (fileTree, query) => {
  if (!query) return fileTree;
  const filteredTree = {};
  Object.keys(fileTree).forEach(key => {
    if (key.toLowerCase().includes(query.toLowerCase())) {
      filteredTree[key] = fileTree[key];
    } else if (fileTree[key] && typeof fileTree[key] === 'object') {
      const filteredSubTree = filterFiles(fileTree[key], query);
      if (Object.keys(filteredSubTree).length > 0) {
        filteredTree[key] = filteredSubTree;
      }
    }
  });
  return filteredTree;
};

const ConfigFiles = () => {
  const [selectedFolder, setSelectedFolder] = useState(configFolders[0].path);
  const [fileTree, setFileTree] = useState({});
  const [currentPath, setCurrentPath] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [showConfigList, setShowConfigList] = useState(true);
  const [fontSize, setFontSize] = useState(14); // Default font size in pixels
  const [searchQuery, setSearchQuery] = useState('');
  const [iframeSize, setIframeSize] = useState({ width: '100%', height: '600px' });

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/configs/configFiles.json`)
      .then(response => response.json())
      .then(data => {
        const folderTree = {};
        configFolders.forEach(folder => {
          folderTree[folder.path] = buildFileTree(data[folder.path]);
        });
        setFileTree(folderTree);
      })
      .catch(error => console.error('Error fetching file list:', error));
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const filePath = `${process.env.PUBLIC_URL}/configs/${selectedFolder}/${currentPath.join('/')}/${selectedFile}`;
      fetch(filePath)
        .then(response => {
          if (response.ok) {
            const contentType = response.headers.get('Content-Type');
            return contentType.includes('text') || selectedFile.endsWith('.toml') || selectedFile.endsWith('.json') || selectedFile.endsWith('.json5')|| selectedFile.endsWith('.properties') ? response.text() : response.blob();
          }
          throw new Error('File not found');
        })
        .then(content => {
          if (typeof content === 'string') {
            setFileContent(content);
          } else {
            const objectURL = URL.createObjectURL(content);
            setFileContent(objectURL);
          }
        })
        .catch(error => console.error('Error fetching file content:', error));
    }
  }, [selectedFile, selectedFolder, currentPath]);

  useEffect(() => {
    const handleResizeMessage = (event) => {
      if (event.data && event.data.width && event.data.height) {
        setIframeSize({ width: `${event.data.width}px`, height: `${event.data.height}px` });
      }
    };
    
    window.addEventListener('message', handleResizeMessage);

    return () => {
      window.removeEventListener('message', handleResizeMessage);
    };
  }, []);

  const navigateToFolder = (folder) => {
    setCurrentPath(prev => [...prev, folder]);
    setSelectedFile(null);
    setSearchQuery('');
  };

  const navigateUp = () => {
    setCurrentPath(prev => prev.slice(0, -1));
    setSelectedFile(null);
    setSearchQuery('');
  };

  const getCurrentFiles = () => {
    let current = fileTree[selectedFolder];
    for (const folder of currentPath) {
      current = current[folder];
    }
    return current;
  };

  const handleBackToConfigList = () => {
    setSelectedFile(null);
    setShowConfigList(true);
    setCurrentPath([]);
    setSearchQuery('');
  };

  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 2);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => Math.max(prevSize - 2, 10)); // Minimum font size of 10px
  };

  const currentFiles = getCurrentFiles();
  const filteredFiles = filterFiles(currentFiles, searchQuery);

  return (
    <div className="ConfigFiles">
      <h2>Configuration Files</h2>
      <div className="folder-select">
        <select onChange={e => { setSelectedFolder(e.target.value); setCurrentPath([]); setSelectedFile(null); }} value={selectedFolder}>
          {configFolders.map(folder => (
            <option key={folder.path} value={folder.path}>{folder.name}</option>
          ))}
        </select>
      </div>
      {showConfigList ? (
        <>
          <input
            type="text"
            placeholder="Search files..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="search-input"
          />
          {currentPath.length > 0 && <button className="toggle-button" onClick={navigateUp}>Back</button>}
          <ul>
            {filteredFiles && Object.keys(filteredFiles).sort((a, b) => {
              if (filteredFiles[a] && !filteredFiles[b]) return -1;
              if (!filteredFiles[a] && filteredFiles[b]) return 1;
              return a.localeCompare(b);
            }).map((fileOrFolder, index) => (
              filteredFiles[fileOrFolder] === null ? (
                <li key={index} className="file" onClick={() => { setSelectedFile(fileOrFolder); setShowConfigList(false); setSearchQuery(''); }}>
                  {fileOrFolder}
                </li>
              ) : (
                <li key={index} className="folder" onClick={() => { navigateToFolder(fileOrFolder); setSearchQuery(''); }}>
                  📁 {fileOrFolder}
                </li>
              )
            ))}
          </ul>
        </>
      ) : (
        <button className="toggle-button" onClick={handleBackToConfigList}>Show Config List</button>
      )}
      <section id="midi-section" className="midi-section" style={{ width: iframeSize.width }}>
        {selectedFile && (
          <div className="file-content">
            <h3>{selectedFile}</h3>
            <div className="font-size-controls">
              <button onClick={decreaseFontSize}>A-</button>
              <button onClick={increaseFontSize}>A+</button>
            </div>
            {selectedFile.endsWith('.mid') ? (
              <iframe
                id="responsive-iframe"
                src={`${process.env.PUBLIC_URL}/midi-player.html?file=${fileContent}`}
                style={{ width: iframeSize.width, height: iframeSize.height, border: 'none' }}
                title="MIDI Player"
              ></iframe>
            ) : (
              <pre style={{ fontSize: `${fontSize}px` }}>{fileContent}</pre>
            )}
            <button className="toggle-button" onClick={handleBackToConfigList}>Back to Config List</button>
          </div>
        )}
      </section>
    </div>
  );
};

export default ConfigFiles;
