// client/src/components/HistoryOfWinners.js

import React from 'react';
import './HistoryOfWinners.css';

const HistoryOfWinners = () => {
  return (
    <div className="HistoryOfWinners">
      <h2>History Of Winners</h2>
      <p>No one has one yet.</p>
    </div>
  );
};

export default HistoryOfWinners;
