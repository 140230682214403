import React from 'react';
import './Recommended.css';

const recommendedMods = [
  {
    name: "Build Guide",
    link: "https://www.curseforge.com/minecraft/mc-mods/build-guide",
    description: "A mod to help you with building structures by providing visual guides."
  },
  {
    name: "Jade",
    link: "https://www.curseforge.com/minecraft/mc-mods/jade",
    description: "A mod that displays block and entity information in a UI."
  },
  {
    name: "Configured",
    link: "https://www.curseforge.com/minecraft/mc-mods/configured",
    description: "A client-side mod to help with mod configuration by providing an in-game configuration GUI."
  }
];

const Recommended = () => {
  return (
    <div className="Recommended">
      <h2>Recommended Mods</h2>
      <ul>
        {recommendedMods.map((mod, index) => (
          <li key={index}>
            <a href={mod.link} target="_blank" rel="noopener noreferrer">
              {mod.name}
            </a>
            : {mod.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Recommended;
